import { defineStore } from 'pinia';
import { AuthStoreModel } from '@/stores/AuthStore/AuthStore.model';

export const useAuthStore = defineStore({
  id: 'storeAuth',
  state: (): AuthStoreModel => {
    return {
      user: {},
      accessToken: '',
      errorMsg: ''
    }
  },
  getters: {
    isLoggedIn(): boolean {
      return !!this.accessToken;
    }
  },
  actions: {}
})
