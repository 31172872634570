<template>
  <div class="global-overlay" :class="{'open': navbarStatus}" @click="closeNavbar"></div>
  <main>
    <MainHeader @toggle-navbar="toggleNavbar" v-if="!isJobOffer()"></MainHeader>
    <div class="content">
      <router-view />
    </div>
    <MainFooter v-if="!isJobOffer()"></MainFooter>
  </main>

  <ConfirmDialog v-if="updateExists"
                 :description="newVersionDialog.description"
                 :rejectBtnLabel="newVersionDialog.rejectBtnLabel"
                 :acceptBtnLabel="newVersionDialog.acceptBtnLabel"
                 @accept="refreshApp"
                 @reject="closeNewVersionDialog">
  </ConfirmDialog>

  <button class="scroll-to-top-wrapper" v-if="scrollToTopButtonStatus && !isJobOffer()" @click="scrollToTop">
    <i class="fas fa-chevron-up"></i>
    <span>nach oben</span>
  </button>

  <SidebarMenu ref="sidebarRef" @toggle-navbar="toggleNavbar" :navbarStatus="navbarStatus"></SidebarMenu>
  <Cookies v-if="cookiesStatus === null"></Cookies>
  <Loader v-if="loaderStatus"></Loader>
</template>

<style lang="scss">
@import "@/assets/styles/z-index.scss";
@import "@/assets/styles/colors.scss";
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/mixins.scss";

@font-face {
  font-family: "Avenir W01";
  src: url('@/assets/fonts/avenir-bold.woff2') format("woff2");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir W01";
  src: url('@/assets/fonts/avenir-regular.woff2') format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir W01";
  src: url('@/assets/fonts/avenir-italic.woff2') format("woff2");
  font-weight: 400;
  font-style: oblique;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;

  font-size: 1rem;
  line-height: 1.75;
}

* {
  box-sizing: border-box;
}

h1, h2, h3 {
  line-height: 1.1;
}

div.global-overlay {
  display: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $overlay-index;
  background-color: $color-black;
  animation: dimmer 0.5s normal forwards ease-in-out;

  @keyframes dimmer {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 0.5;
    }
  }

  &.open {
    display: block;
  }
}

main {
  max-width: $maxPageWidth;
  background-color: $color-smoke;
  min-height: 100vh;
  display: grid !important;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  margin: 0 auto;
}

button.scroll-to-top-wrapper {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: $move-to-top-scroll-index;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  border: none;
  background-color: $color-white;
  box-shadow: 0 0 15px -5px $color-black;
  cursor: pointer;

  @include respond-to(min-width, 768px) {
    padding: 1rem 1.25rem;
  }

  &:hover {
    background-color: $color-blue;

    & > i,
    & > span {
      color: $color-white;
    }
  }

  & > i {
    font-size: 1rem;
    color: $color-blue;

    @include respond-to(min-width, 768px) {
      margin-right: 1rem;
    }
  }

  & > span {
    text-transform: uppercase;
    color: $color-blue;
    font-weight: 500;
    font-size: 1rem;
    display: none;

    @include respond-to(min-width, 768px) {
      display: inline-block;
    }
  }
}

#app {
  text-align: center;
  color: #2c3e50;

  *:not(.fa-classic, .fa-regular, .fa-solid, .far, .fas, .fa-brands, .fab) {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

footer {
  align-self: flex-end;
}

div.v-expansion-panel {
  border-radius: 0;

  &.v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
    min-height: auto !important;
  }

  & > div.v-expansion-panel__shadow {
    display: none;
  }

  & > button.v-expansion-panel-title {
    & > span.v-expansion-panel-title__overlay,
    & > span.v-expansion-panel-title__icon {
      display: none !important;
    }
  }
}

div.v-overlay-container {
  & > div.v-tooltip {
    font-family: "Avenir W01", sans-serif;

    &.error-tooltip {
      & > div.v-overlay__content {
        background: $color-red;

        & > ul {
          padding: 0;
          margin: 0 0 0 0.75rem;
        }
      }
    }
  }
}

</style>
<script lang="ts">
import { computed, defineAsyncComponent, defineComponent, onMounted, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import Cookies from '@/components/partial/Cookies.vue';
  import { getLocalStorage, removeLocalStorage } from '@/services/LocalStorage/LocalStorage.service';
  import { LocalStorageKeysEnum } from '@/enums/LocalStorageKeys.enum';
  import dayjs from 'dayjs';
  import { serviceWorkerUpdate } from '@/mixins/serviceWorkerUpdate';
import { useLoaderStore } from '@/stores/LoaderStore/LoaderStore';

  const SidebarMenu = defineAsyncComponent(() => import('@/components/SidebarMenu.vue'));
  const MainHeader = defineAsyncComponent(() => import('@/components/MainHeader.vue'));
  const MainFooter = defineAsyncComponent(() => import('@/components/MainFooter.vue'));
  const ConfirmDialog = defineAsyncComponent(() => import('@/components/partial/ConfirmDialog.vue'));
  const Loader = defineAsyncComponent(() => import('@/components/Loader.vue'));

  export default defineComponent({
    name: 'App',
    components: {
      Loader,
      ConfirmDialog,
      Cookies,
      SidebarMenu,
      MainHeader,
      MainFooter
    },
    setup() {
      const route = useRoute();
      const navbarStatus = ref(false);
      const scrollToTopButtonStatus = ref(false);
      const JOB_OFFER_URL = ref('job-anziege');
      const cookiesStatus = ref<boolean | null>(null);
      const snackbarTest = ref(true);
      const newVersionDialog = {
        description: 'Eine neue Version der Seite ist verfügbar. Klicken Sie auf die Schaltfläche „Akzeptieren“, um sie zu aktualisieren.',
        acceptBtnLabel: 'Akzeptieren',
        rejectBtnLabel: 'Ablehnen'
      }
      const { refreshing, registration, updateExists, refreshApp } = serviceWorkerUpdate();
      const loaderStore = useLoaderStore();
      const loaderStatus = computed(() => loaderStore.status);
      const sidebarRef = ref();

      onMounted(() => {
        getCookiesConsentStatus();

        window.addEventListener('scroll', () => {
          scrollToTopButtonStatus.value = window.scrollY > 300;
        });

        window.addEventListener('cookies-consent-status-changed', (event) => {
          getCookiesConsentStatus();
        })
      });

      const toggleNavbar = () => {
        navbarStatus.value = !navbarStatus.value;
      }

      const closeNavbar = () => {
        sidebarRef.value?.closeMenuItems();
        navbarStatus.value = false;
      }

      const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }

      const isJobOffer = () => {
        return route.path.includes(JOB_OFFER_URL.value);
      }

      const getCookiesConsentStatus = () => {
        const cookieConsentStatusString = getLocalStorage(LocalStorageKeysEnum.COOKIE_CONSENT_STATUS);
        const cookieConsentStatus = cookieConsentStatusString ? JSON.parse(cookieConsentStatusString) : null;

        if (cookieConsentStatus !== null) {
          cookiesStatus.value = cookieConsentStatus.status;

          if (dayjs(cookieConsentStatus.expiration_date).isValid() && dayjs().isAfter(dayjs(cookieConsentStatus.expiration_date))) {
            removeLocalStorage(LocalStorageKeysEnum.COOKIE_CONSENT_STATUS);
            cookiesStatus.value = null;
          }
        }
      }

      const closeNewVersionDialog = () => {
        updateExists.value = false;
      }

      return {
        scrollToTopButtonStatus,
        navbarStatus,
        cookiesStatus,
        updateExists,
        snackbarTest,
        newVersionDialog,
        loaderStatus,
        sidebarRef,
        scrollToTop,
        closeNavbar,
        toggleNavbar,
        isJobOffer,
        refreshApp,
        closeNewVersionDialog
      }
    }
  });
</script>
