import { apiGet } from '@/api/api';
import { PageModel } from '@/models/Page.model';
import { AccordionModel } from '@/models/Accordion.model';

const getTextPage = async (id: string | number): Promise<PageModel> => {
  return await apiGet(`/api/cms/textPage?type=${id}`).then(page => {
    page?.accordion?.forEach((p: AccordionModel) => {
      p.status = false;
    });

    return page;
  });
}

export default {
  getTextPage
}
