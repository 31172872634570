<template>
  <div class="cookies-popup-wrapper">
    <div class="popup-content">
      <span class="description">Diese Website würde gerne Cookies verwenden, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten.</span>
      <span class="inform">Hier erfahren Sie, welche Cookies wir zu welchem Zweck verwenden und wie Sie die Einstellungen anpassen können: <router-link to="/datenschutz">Datenschutz</router-link></span>
      <button class="allow-cookies-btn" @click="setCookiesConsent">Cookies erlauben</button>
      <router-link to="/datenschutz" class="settings">Einstellungen</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { setLocalStorage } from '@/services/LocalStorage/LocalStorage.service';
import { LocalStorageKeysEnum } from '@/enums/LocalStorageKeys.enum';
import dayjs from 'dayjs';
import { DatesFormatsEnum } from '@/enums/DatesFormats.enum';

export default defineComponent({
  name: 'Cookies',
  setup() {
    const setCookiesConsent = () => {
      setLocalStorage(LocalStorageKeysEnum.COOKIE_CONSENT_STATUS, JSON.stringify({ status: true, expiration_date: dayjs().add(6, 'month').format(DatesFormatsEnum.DEFAULT_WITHOUT_TIME) }));
      window.dispatchEvent(new CustomEvent('cookies-consent-status-changed'));
    }

    return {
      setCookiesConsent
    }
  }
})
</script>

<style scoped lang="scss">
  @import "@/assets/styles/z-index";
  @import "@/assets/styles/colors";
  @import "@/assets/styles/variables";
  @import "@/assets/styles/mixins";

  div.cookies-popup-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: $modal-index;
    background-color: $color-white;
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

    & > div.popup-content {
      max-width: $maxContentWidth;
      display: grid;
      grid-template-areas: "description"
                           "inform"
                           "allowCookiesBtn"
                           "settings";
      grid-gap: 0.5rem 3rem;
      justify-content: center;
      align-items: center;
      text-align: left;

      @include respond-to(min-width, 768px) {
        grid-template-areas: "description allowCookiesBtn"
                             "inform settings";
      }

      & > span {
        font-size: 0.75rem;
        font-weight: 500;

        &.description {
          grid-area: description;
        }

        &.inform {
          grid-area: inform;
        }

        & > a {
          color: $color-blue;
        }
      }

      & > button.allow-cookies-btn {
        grid-area: allowCookiesBtn;
        background-color: $color-blue;
        color: $color-white;
        padding: 0.625rem 1rem;
        font-weight: 700;
        cursor: pointer;
        border: none;
        width: fit-content;
      }

      & > a.settings {
        grid-area: settings;
        color: $color-blue;
      }
    }
  }
</style>
