export enum TextViewSideBarAdditionalBoxTypesEnum {
  CONTACT_TEAM = 'contactTeam',
  PRODUCT_CONTACT_TEAM = 'productContactTeam',
  OWNER = 'owner',
  SERVICES = 'services',
  TELEPHONY = 'telephony',
  PRODUCT = 'product',
  BENEFITS = 'benefits'
}

export enum TextViewSideBarAdditionalBoxListElementsTypesEnum {
  LINK = 'link',
  NORMAL = 'normal'
}
