import { apiGet, apiPost } from '@/api/api';
import {
  InternalProductModel,
  InternalProductsWithPaginationModel,
  ProductFilterItem,
  ProductModel,
  ProductModelWithPaginationModel,
  ProductTypes
} from '@/models/Product.model';
import { ProductsFiltersRequestModel } from '@/models/ProductsFilters.model';
import { DefaultPaginationRequestModel } from '@/models/Pagination.model';

const getProducts = async (filter: ProductsFiltersRequestModel): Promise<ProductModelWithPaginationModel> => {
  const productsFilter = { ...filter };
  productsFilter.searchTerm = productsFilter?.searchTerm ? productsFilter.searchTerm?.toLowerCase() : '';

  return await apiPost('/api/products/query', {}, productsFilter).then(products => {
    products?.filtersSummary?.filters?.map((filter: ProductFilterItem) => {
      filter.options = [];
      return filter.availableValues.map(value => {
        filter.options.push({
          label: value,
          value,
          status: false
        });

        return filter;
      })
    })

    return products;
  });
}

const getProductDetails = async (articleNumber: string): Promise<ProductModel> => {
  return await apiGet(`/api/products?articleNumber=${articleNumber}`);
}

const getProductTypes = async (): Promise<ProductTypes> => {
  return await apiGet('/api/products/types', {});
}

const getInternalProducts = async (addressNumber: number | string, pagination: DefaultPaginationRequestModel, searchTerm: string): Promise<InternalProductsWithPaginationModel> => {
  return await apiPost('/api/internal-products/query', {}, { addressNumber, page: pagination.page, pageSize: pagination.pageSize, searchTerm }).then(articles => {
    articles?.items?.forEach((article: InternalProductModel) => {
      article.amount = 1;
    });
    return articles;
  });
}

export default {
  getProducts,
  getProductDetails,
  getProductTypes,
  getInternalProducts
}
