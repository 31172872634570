import { GalleryModel } from '@/models/Home.model';
import { ContactsModel } from '@/models/Contact.model';
import { apiGet } from '@/api/api';
import { HistoryModel } from '@/models/History.model';
import { PageModel } from '@/models/Page.model';

const getHomePage = async (): Promise<GalleryModel> => {
  return await apiGet('/api/cms/homePage');
}

const getContactPage = async (): Promise<ContactsModel> => {
  return await apiGet('/api/cms/contactPage');
}

const getTextPage = async (): Promise<PageModel> => {
  return await apiGet('/api/cms/textPage');
}

const getHistoryPage = async (): Promise<HistoryModel> => {
  return await apiGet('/api/cms/historyPage');
}

export default {
  getHomePage,
  getContactPage,
  getTextPage,
  getHistoryPage
}
