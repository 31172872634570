import {apiGet} from "@/api/api";

const URL = 'https://p670160.mittwaldserver.info/files/test/cms/jobs/';

const getServiceJobOffer = async (): Promise<any> => {
  return await apiGet(`${URL}/service.pdf`);
}

const getSalesJobOffer = async (): Promise<any> => {
  return await apiGet(`${URL}/sales.pdf`);
}

export default { getServiceJobOffer, getSalesJobOffer }
