import CMSController from './CMS';
import BasketController from './Basket';
import ProductsController from './Products';
import AddressController from './Address';
import TextPageController from './TextPage';
import ServiceController from './Services';
import JobOfferController from './JobOffers';

export const API = {
  cms: CMSController,
  basket: BasketController,
  products: ProductsController,
  address: AddressController,
  textPage: TextPageController,
  service: ServiceController,
  jobOffer: JobOfferController
}
