import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!
  const _component_Cookies = _resolveComponent("Cookies")!
  const _component_Loader = _resolveComponent("Loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["global-overlay", {'open': _ctx.navbarStatus}]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeNavbar && _ctx.closeNavbar(...args)))
    }, null, 2),
    _createElementVNode("main", null, [
      (!_ctx.isJobOffer())
        ? (_openBlock(), _createBlock(_component_MainHeader, {
            key: 0,
            onToggleNavbar: _ctx.toggleNavbar
          }, null, 8, ["onToggleNavbar"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_view)
      ]),
      (!_ctx.isJobOffer())
        ? (_openBlock(), _createBlock(_component_MainFooter, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.updateExists)
      ? (_openBlock(), _createBlock(_component_ConfirmDialog, {
          key: 0,
          description: _ctx.newVersionDialog.description,
          rejectBtnLabel: _ctx.newVersionDialog.rejectBtnLabel,
          acceptBtnLabel: _ctx.newVersionDialog.acceptBtnLabel,
          onAccept: _ctx.refreshApp,
          onReject: _ctx.closeNewVersionDialog
        }, null, 8, ["description", "rejectBtnLabel", "acceptBtnLabel", "onAccept", "onReject"]))
      : _createCommentVNode("", true),
    (_ctx.scrollToTopButtonStatus && !_ctx.isJobOffer())
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "scroll-to-top-wrapper",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.scrollToTop && _ctx.scrollToTop(...args)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("i", { class: "fas fa-chevron-up" }, null, -1),
          _createElementVNode("span", null, "nach oben", -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_SidebarMenu, {
      ref: "sidebarRef",
      onToggleNavbar: _ctx.toggleNavbar,
      navbarStatus: _ctx.navbarStatus
    }, null, 8, ["onToggleNavbar", "navbarStatus"]),
    (_ctx.cookiesStatus === null)
      ? (_openBlock(), _createBlock(_component_Cookies, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.loaderStatus)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 3 }))
      : _createCommentVNode("", true)
  ], 64))
}