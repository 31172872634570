import { ref, onMounted, onUnmounted } from 'vue';

interface ServiceWorkerRegistrationEvent extends Event {
  detail: ServiceWorkerRegistration;
}

export function serviceWorkerUpdate() {
  const refreshing = ref(false);
  const registration = ref<ServiceWorkerRegistration | null>(null);
  const updateExists = ref(false);

  const updateAvailable = (event: ServiceWorkerRegistrationEvent) => {
    registration.value = event.detail;
    updateExists.value = true;
  };

  const refreshApp = () => {
    updateExists.value = false;
    if (!registration.value || !registration.value.waiting) return;
    registration.value.waiting.postMessage({ type: 'SKIP_WAITING' });
  };

  onMounted(() => {
    document.addEventListener('swUpdated', updateAvailable as EventListener, { once: true });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing.value) return;
      refreshing.value = true;
      window.location.reload();
    });
  });

  onUnmounted(() => {
    document.removeEventListener('swUpdated', updateAvailable as EventListener);
  });

  return {
    refreshing,
    registration,
    updateExists,
    refreshApp
  };
}
