import { createApp, defineAsyncComponent } from 'vue'
import router from './router'
import App from '@/App.vue';

import { createVuetify } from 'vuetify';
import './registerServiceWorker'
import { createPinia } from 'pinia';
import keycloakService from '@/services/Keycloak/Keycloak';

const DefaultTextView = defineAsyncComponent(() => import('@/views/DefaultTextView.vue'));
const DefaultProductView = defineAsyncComponent(() => import('@/views/DefaultProductView.vue'));
const CollectivePage = defineAsyncComponent(() => import('@/components/CollectivePage.vue'));

const vuetify = createVuetify();
const pinia = createPinia();

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(pinia)
  .component('default-text-view', DefaultTextView)
  .component('default-product-view', DefaultProductView)
  .component('collective-view', CollectivePage)

await keycloakService.CallInit();

app.mount('#app')
