import { defineStore } from 'pinia';
import { LoaderStoreModel } from '@/stores/LoaderStore/LoaderStore.model';

export const useLoaderStore = defineStore({
  id: 'storeLoader',
  state: (): LoaderStoreModel => {
    return {
      status: false
    }
  },
  getters: {
    loaderStatus(): boolean {
      return this.status
    }
  },
  actions: {
    show() {
      this.status = true;
    },
    hide() {
      this.status = false;
    }
  }
})
