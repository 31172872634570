import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/home/home-text-slider/text-slide-1.webp'
import _imports_1 from '@/assets/images/home/home-text-slider/text-slide-2.webp'


const _hoisted_1 = { class: "home-wrapper" }
const _hoisted_2 = { class: "home-gallery-wrapper" }
const _hoisted_3 = { class: "main-info-wrapper" }
const _hoisted_4 = { class: "gallery-overlay-wrapper" }
const _hoisted_5 = { class: "content-wrapper" }
const _hoisted_6 = { class: "slide" }
const _hoisted_7 = { class: "slide" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeGallery = _resolveComponent("HomeGallery")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SolutionForEveryIndustry = _resolveComponent("SolutionForEveryIndustry")!
  const _component_OurServices = _resolveComponent("OurServices")!
  const _component_WorkingAtmosphere = _resolveComponent("WorkingAtmosphere")!
  const _component_OurPartners = _resolveComponent("OurPartners")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HomeGallery, {
        images: _ctx.sliders?.galleries
      }, null, 8, ["images"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("a", { href: "tel:+49054144070" }, [
          _createElementVNode("i", { class: "fas fa-phone" }),
          _createElementVNode("h3", null, "Rufen Sie uns an"),
          _createElementVNode("p", null, "+49 0541 44 070")
        ], -1)),
        _createVNode(_component_router_link, { to: "/service/zaehlerstand-melden" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("i", { class: "fas fa-gear" }, null, -1),
            _createElementVNode("h3", null, "Unser Service", -1),
            _createElementVNode("p", null, "Jetzt entdecken!", -1)
          ])),
          _: 1
        }),
        _cache[2] || (_cache[2] = _createElementVNode("a", null, [
          _createElementVNode("i", { class: "fas fa-lock" }),
          _createElementVNode("h3", null, "B2B ICT-Marketplace"),
          _createElementVNode("p", null, "Onlineshop")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("a", {
          href: "https://blz-shop.xn--brobest-n2a.de/",
          target: "_blank"
        }, [
          _createElementVNode("i", { class: "fas fa-cart-shopping" }),
          _createElementVNode("h3", null, "Bürobedarf"),
          _createElementVNode("p", null, "Onlineshop")
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("figure", null, [
            _createVNode(_component_router_link, { to: "/produkte" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  loading: "lazy",
                  alt: "Servicetechniker bei BLZ Scherz + Cramer",
                  width: "450",
                  height: "254",
                  title: "Servicetechniker bei BLZ Scherz + Cramer"
                }, null, -1)
              ])),
              _: 1
            }),
            _cache[5] || (_cache[5] = _createElementVNode("figcaption", null, "Servicetechniker bei BLZ Scherz + Cramer", -1))
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, { to: "/produkte" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createElementVNode("h3", null, "Produkte", -1)
              ])),
              _: 1
            }),
            _cache[8] || (_cache[8] = _createElementVNode("p", { class: "description" }, " Von Bürobedarf über Büroeinrichtung, Drucker, Kopierer, Scanner und Multifunktiongeräte. Software-Lösungen gehören genauso zu unserem Leistungsspektrum wie Netzwerk und EDV. ", -1)),
            _createVNode(_component_router_link, {
              to: "/produkte",
              class: "inform"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("Jetzt informieren")
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("figure", null, [
            _createVNode(_component_router_link, { to: "/leistungsbereiche" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  loading: "lazy",
                  alt: "Die Leistungsbereiche von BLZ Scherz + Cramer",
                  width: "450",
                  height: "254",
                  title: "Die Leistungsbereiche von BLZ Scherz + Cramer"
                }, null, -1)
              ])),
              _: 1
            }),
            _cache[10] || (_cache[10] = _createElementVNode("figcaption", null, "Die Leistungsbereiche von BLZ Scherz + Cramer", -1))
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, { to: "/leistungsbereiche" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createElementVNode("h3", null, "Leistungsbereiche", -1)
              ])),
              _: 1
            }),
            _cache[13] || (_cache[13] = _createElementVNode("p", { class: "description" }, "Wir bieten Ihnen sowohl in der Beratung als auch in der Installation und Wartung umfassende Kompetenzen.", -1)),
            _createVNode(_component_router_link, {
              to: "/leistungsbereiche",
              class: "inform"
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Jetzt informieren")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ]),
    _createVNode(_component_SolutionForEveryIndustry),
    _createVNode(_component_OurServices),
    _createVNode(_component_WorkingAtmosphere),
    _createVNode(_component_OurPartners)
  ]))
}