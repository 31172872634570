<template>
  <div class="home-wrapper">
    <div class="home-gallery-wrapper">
      <HomeGallery :images="sliders?.galleries"></HomeGallery>
    </div>

    <div class="main-info-wrapper">
      <div class="gallery-overlay-wrapper">
        <a href="tel:+49054144070">
          <i class="fas fa-phone"></i>
          <h3>Rufen Sie uns an</h3>
          <p>+49 0541 44 070</p>
        </a>
        <router-link to="/service/zaehlerstand-melden">
          <i class="fas fa-gear"></i>
          <h3>Unser Service</h3>
          <p>Jetzt entdecken!</p>
        </router-link>
        <a>
          <i class="fas fa-lock"></i>
          <h3>B2B ICT-Marketplace</h3>
          <p>Onlineshop</p>
        </a>
        <a href="https://blz-shop.xn--brobest-n2a.de/" target="_blank">
          <i class="fas fa-cart-shopping"></i>
          <h3>Bürobedarf</h3>
          <p>Onlineshop</p>
        </a>
      </div>
      <div class="content-wrapper">
        <div class="slide">
          <figure>
            <router-link to="/produkte">
              <img src="@/assets/images/home/home-text-slider/text-slide-1.webp" loading="lazy" alt="Servicetechniker bei BLZ Scherz + Cramer" width="450" height="254" title="Servicetechniker bei BLZ Scherz + Cramer" />
            </router-link>
            <figcaption>Servicetechniker bei BLZ Scherz + Cramer</figcaption>
          </figure>
          <div>
            <router-link to="/produkte">
              <h3>Produkte</h3>
            </router-link>
            <p class="description">
              Von Bürobedarf über Büroeinrichtung, Drucker, Kopierer, Scanner und Multifunktiongeräte. Software-Lösungen gehören genauso zu unserem Leistungsspektrum wie Netzwerk und EDV.
            </p>
            <router-link to="/produkte" class="inform">Jetzt informieren</router-link>
          </div>
        </div>
        <div class="slide">
          <figure>
            <router-link to="/leistungsbereiche">
              <img src="@/assets/images/home/home-text-slider/text-slide-2.webp" loading="lazy" alt="Die Leistungsbereiche von BLZ Scherz + Cramer" width="450" height="254" title="Die Leistungsbereiche von BLZ Scherz + Cramer" />
            </router-link>
            <figcaption>Die Leistungsbereiche von BLZ Scherz + Cramer</figcaption>
          </figure>
          <div>
            <router-link to="/leistungsbereiche">
              <h3>Leistungsbereiche</h3>
            </router-link>
            <p class="description">Wir bieten Ihnen sowohl in der Beratung als auch in der Installation und Wartung umfassende Kompetenzen.</p>
            <router-link to="/leistungsbereiche" class="inform">Jetzt informieren</router-link>
          </div>
        </div>
      </div>
    </div>

    <SolutionForEveryIndustry></SolutionForEveryIndustry>
    <OurServices></OurServices>
    <WorkingAtmosphere></WorkingAtmosphere>
    <OurPartners></OurPartners>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, onMounted, ref } from 'vue'
import { API } from '@/services';
import { GalleryModel } from '@/models/Home.model';

const HomeGallery = defineAsyncComponent(() => import('@/components/home/HomeGallery.vue'));
const SolutionForEveryIndustry = defineAsyncComponent(() => import('@/components/home/SolutionForEveryIndustry.vue'));
const WorkingAtmosphere = defineAsyncComponent(() => import('@/components/home/WorkingAtmosphere.vue'));
const OurServices = defineAsyncComponent(() => import('@/components/home/OurServices.vue'));
const OurPartners = defineAsyncComponent(() => import('@/components/home/OurPartners.vue'));

export default defineComponent({
  name: 'HomeView',
  components: {
    OurPartners,
    WorkingAtmosphere,
    OurServices,
    SolutionForEveryIndustry,
    HomeGallery
  },
  setup() {
    const sliders = ref<GalleryModel>();

    onMounted(async () => {
      await getHomePage();
    })

    const getHomePage = async () => {
      sliders.value = await API.cms.getHomePage();
    }

    return {
      sliders
    }
  }
})
</script>

<style lang="scss">
@import '@/assets/styles/variables.scss';
@import '@/assets/styles/colors.scss';
@import '@/assets/styles/mixins.scss';
@import '@/assets/styles/reusable.scss';

div.home-wrapper {
  & > div.home-gallery-wrapper {
    --home-gallery-height: 400px;

    height: var(--home-gallery-height);

    @include respond-to(min-width, 1024px) {
      --home-gallery-height: 600px;
    }
  }

  & > div.main-info-wrapper {
    max-width: $maxWidthMediumElements;
    margin: auto;

    @include respond-to(min-width, 768px) {
      transform: translateY(calc($homeGalleryOverlayHeight * -1));
    }

    & > div.gallery-overlay-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      background-color: $color-white;

      @include respond-to(min-width, 768px) {
        box-shadow: 0 -3px 3px 0 rgba(0,0,0,0.2);
        background-color: rgba($color-white, 0.8);
      }

      & > a {
        height: $homeGalleryOverlayHeight;
        display: grid;
        grid-template-areas: 'icon'
                             'title';
        grid-template-columns: 100%;
        grid-gap: 0.5rem;
        grid-template-rows: 1fr 1fr;
        align-items: center;
        justify-items: center;
        cursor: pointer;
        transition: 300ms ease-in-out;
        text-decoration: none;

        @include respond-to(min-width, 1024px) {
          grid-template-columns: 30% 70%;
          grid-gap: 0;
          grid-template-areas: 'icon title'
                               'icon description';
        }

        &:hover {
          background-color: rgba($color-white, 0.5);
          transition: 500ms ease-in-out;
        }

        &:not(:last-child) {
          border-right: 1px solid $color-gray;
        }

        & > h3,
        & > p {
          margin: 0;
          text-align: center;

          @include respond-to(min-width, 1024px) {
            justify-self: flex-start;
          }
        }

        & > i,
        & > svg {
          grid-area: icon;
          color: $color-blue;
          font-size: 1.25rem;
          align-self: flex-end;

          @include respond-to(min-width, 1024px) {
            align-self: center;
          }
        }

        & > svg {
          justify-self: center;
        }

        & > i {
          display: flex;
        }

        & > h3 {
          grid-area: title;
          font-size: 0.8125rem;
          align-self: flex-start;
          color: $color-blue;
          font-weight: 500;
          padding: 0 0.25rem;

          @include respond-to(min-width, 1024px) {
            font-size: 1rem;
            align-self: flex-end;
          }
        }

        & > p {
          grid-area: description;
          font-size: 1rem;
          align-self: flex-start;
          font-weight: bold;
          display: none;
          color: $color-black;

          @include respond-to(min-width, 1024px) {
            display: block;
          }
        }
      }
    }

    & > div.content-wrapper {
      background-color: $color-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      grid-gap: 2rem;
      padding: 2rem 1rem;
      margin: 1rem;

      @include respond-to(min-width, 568px) {
        align-items: flex-start;
        flex-direction: row;
        grid-gap: 1rem;
      }

      @include respond-to(min-width, 768px) {
        margin: 0;
      }

      & > div.slide {
        --slide-width: 450px;

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include respond-to(min-width, 568px) {
          width: var(--slide-width);
        }

        & > figure {
          width: 100%;
          position: relative;
          margin: 0;

          @include respond-to(min-width, 568px) {
            width: fit-content;
          }

          & > a {
            & > img {
              width: 100%;
              height: auto;
              min-height: 250px;
              object-fit: cover;

              @include respond-to(min-width, 568px) {
                max-width: var(--slide-width);
              }
            }
          }

          & > figcaption {
            position: absolute;
            bottom: 20px;
            right: 0;
            z-index: 10;
            padding: 0.625rem 0.625rem 0.625rem 0;
            max-width: 200px;
            color: $color-white;
            font-size: 0.8125rem;
            background: $color-blue;
            font-weight: bold;
            text-align: left;

            &::after {
              content: "";
              position: absolute;
              display: block;
              width: 40px;
              height: 100%;
              top: 0;
              right: 100%;
              z-index: -1;
              background: $color-blue;
              transform-origin: top right;
              transform: skew(150deg,0);
              margin-right: -39px;
            }
          }
        }

        & > div {
          text-align: left;

          & > a {
            cursor: pointer;
            text-decoration: none;
            color: $color-blue;
            font-weight: bold;
            font-size: 0.9375rem;

            & > h3 {
              font-size: 1.25rem;
              color: $color-blue;
              margin: 0.5rem 0;
            }
          }

          & > p.description {
            margin: 0.5rem 0;
            font-size: 0.9375rem;
            font-weight: 500;
          }
        }
      }
    }
  }
}
</style>
