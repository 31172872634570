import axios from 'axios';
import { useAuthStore } from '@/stores/AuthStore/AuthStore';
import keycloakService from '@/services/Keycloak/Keycloak';
import { useLoaderStore } from '@/stores/LoaderStore/LoaderStore';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
});

http.interceptors.request.use(
  (config) => {
    const loaderStore = useLoaderStore();
    // @ts-ignore
    if (!config?.hideLoader) {
      loaderStore.show();
    }

    const authStore = useAuthStore();
    const token = authStore.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    console.error('interceptor request error', error);
    return Promise.reject(error);
  }
)

http.interceptors.response.use(
  (res) => {
    const loaderStore = useLoaderStore();
    loaderStore.hide();
    return res;
  },
  async (error) => {
    const oriConfig = error.config;
    const loaderStore = useLoaderStore();
    loaderStore.hide();

    if (error.response?.status === 401 && !oriConfig._retry) {
      const authStore = useAuthStore();
      oriConfig._retry = true;

      try {
        await keycloakService.CallTokenRefresh();

        oriConfig.headers.Authorization = authStore.accessToken;

        return http(oriConfig);
      } catch (error) {
        console.error('Refresh token failed', error);

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

const apiGet = async (url: string, config = {}) => {
  return await http.get(url, config).then(res => {
    return res?.data;
  }).catch(error => {
    console.error('error', error);
  })
}

const apiPost = async (url: string, config = {}, body = {}) => {
  return await http.post(url, body, config).then(res => {
    return res?.data;
  }).catch(error => {
    console.error('error', error);
  })
}

const apiDelete = async (url: string, config = {}) => {
  return await http.delete(url, config).then(res => {
    return res?.data;
  }).catch(error => {
    console.error('error', error);
  })
}

export {
  apiGet,
  apiPost,
  apiDelete
}
