import Keycloak from 'keycloak-js';
import { useAuthStore } from '@/stores/AuthStore/AuthStore';

const options = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  realm: process.env.VUE_APP_KEYCLOAK_REALM
}

const keycloak = new Keycloak(options);

const init = async () => {
  try {
    const authStore = useAuthStore();
    const authenticated = await keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false, silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html` });
    if (authenticated) {
      authStore.accessToken = keycloak.token as string;
      await fetchUserProfile();
    }
  } catch (error) {
    console.error('Keycloak init failed', error);
  }
}

const fetchUserProfile = async () => {
  const authStore = useAuthStore();
  try {
    authStore.user = await keycloak.loadUserInfo();
  } catch (error) {
    authStore.errorMsg = error as string;
  }
}

const login = async (url: string) => {
  await keycloak.login({ redirectUri: url })
}

const logout = async (url: string) => {
  await keycloak.logout({ redirectUri: url });
}

const refreshToken = async () => {
  const authStore = useAuthStore();
  try {
    await keycloak.updateToken(400);
    authStore.accessToken = keycloak.token as string;
    return keycloak;
  } catch (error) {
    console.error('Failed to refresh token', error);
    window.location.href = keycloak.createLoginUrl();
  }
}

const getToken = () => {
  return keycloak.token;
}

const removeToken = () => {
  keycloak.clearToken();
}

const isTokenExpired = () => {
  return keycloak.isTokenExpired();
}

const isAuthenticated = () => {
  return keycloak.authenticated;
}

const KeycloakService = {
  CallInit: init,
  CallLogin: login,
  CallLogout: logout,
  CallTokenRefresh: refreshToken,
  CallGetToken: getToken,
  CallRemoveToken: removeToken,
  CallIsTokenExpired: isTokenExpired,
  CallIsAuthenticated: isAuthenticated
};

export default KeycloakService;
