import { apiDelete, apiGet, apiPost } from '@/api/api';
import { BasketModel, CreateOrderResponseModel } from '@/models/Basket.model';

const getBasketByDeliveryAddress = async (deliveryAddressNumber: number | string, sequenceNumber: number | string): Promise<BasketModel> => {
  return await apiGet(`/api/baskets/${deliveryAddressNumber}/sequenceNumbers/${sequenceNumber}`);
}

const addItemToBasket = async (deliveryAddressNumber: string | number, sequenceNumber: string | number, articleNumber: string, quantity: number): Promise<void> => {
  return await apiPost('/api/baskets/items', {}, { deliveryAddressNumber, sequenceNumber, articleNumber, quantity });
}

const setBasketItemQuantity = async (deliveryAddressNumber: string | number, sequenceNumber: string | number, articleNumber: string, quantity: number): Promise<void> => {
  return await apiPost('/api/baskets/setQuantity', {}, { deliveryAddressNumber, sequenceNumber, articleNumber, quantity });
}

const deleteItemFromBasket = async (deliveryAddressNumber: string | number, sequenceNumber: string | number, articleNumber: string): Promise<void> => {
  return await apiDelete('/api/baskets/items', { data: { deliveryAddressNumber, sequenceNumber, articleNumber } });
}

const createOrderFromBasket = async (deliveryAddressNumber: number | string, sequenceNumber: number | string, orderNumber: string, hints: string): Promise<CreateOrderResponseModel> => {
  return await apiPost(`/api/baskets/${deliveryAddressNumber}/sequenceNumbers/${sequenceNumber}/orders`, {}, { customOrderNumber: orderNumber, hints });
}

export default {
  getBasketByDeliveryAddress,
  addItemToBasket,
  setBasketItemQuantity,
  deleteItemFromBasket,
  createOrderFromBasket
}
