import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cookies-popup-wrapper" }
const _hoisted_2 = { class: "popup-content" }
const _hoisted_3 = { class: "inform" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "description" }, "Diese Website würde gerne Cookies verwenden, um sicherzustellen, dass Sie die beste Erfahrung auf unserer Website erhalten.", -1)),
      _createElementVNode("span", _hoisted_3, [
        _cache[2] || (_cache[2] = _createTextVNode("Hier erfahren Sie, welche Cookies wir zu welchem Zweck verwenden und wie Sie die Einstellungen anpassen können: ")),
        _createVNode(_component_router_link, { to: "/datenschutz" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Datenschutz")
          ])),
          _: 1
        })
      ]),
      _createElementVNode("button", {
        class: "allow-cookies-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setCookiesConsent && _ctx.setCookiesConsent(...args)))
      }, "Cookies erlauben"),
      _createVNode(_component_router_link, {
        to: "/datenschutz",
        class: "settings"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Einstellungen")
        ])),
        _: 1
      })
    ])
  ]))
}