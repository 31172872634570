const LOCAL_STORAGE_KEYS_PREFIX = 'blz_sc';

const getLocalStorage = (key: string) => {
  return localStorage.getItem(`${LOCAL_STORAGE_KEYS_PREFIX}_${key}`);
}

const setLocalStorage = (key: string, value: string) => {
  localStorage.setItem(`${LOCAL_STORAGE_KEYS_PREFIX}_${key}`, value);
}

const removeLocalStorage = (key: string) => {
  localStorage.removeItem(`${LOCAL_STORAGE_KEYS_PREFIX}_${key}`);
}

export { getLocalStorage, setLocalStorage, removeLocalStorage }
